let header = document.querySelector(".header")
let headerBtn = document.querySelector(".header__burger")
let overlay = document.querySelector(".header__listBg")

headerBtn.addEventListener("click", () => {
    header.classList.toggle("is-on");
    if (header.classList.contains("is-on")) {
        document.body.style.overflowY = "hidden"
    } else {
        document.body.style.overflowY = "auto"
    }
})

overlay.addEventListener("click", () => {
    header.classList.remove("is-on");
    document.body.style.overflowY = "auto"
})