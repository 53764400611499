import Swiper from "swiper/bundle"

var swiper = new Swiper(".mySwiper", {
    speed: 4000,
    autoplay: {
        delay: 3000,
    },
    loop: true,
    effect: "fade",
    fadeEffect: {
        crossFade: true
    },
});

{
    /* <div class="swiper mySwiper">
        <div class="swiper-wrapper">
            <div class="swiper-slide"
                <figure>
                    <img src="" alt="" />
                </figure>
            </div>
        </div>
    </div> */

    // @import '../../node_modules/swiper/swiper-bundle.min.css';

}